<template>       
    <div class="flex-sb">
        <el-button type="success" @click="HandleRoute('/background')">背景调查</el-button>
        <el-button type="success" @click="HandleRoute('/homestay')">寄宿家庭合同</el-button>
        <el-button type="success" @click="HandleRoute('/w9')">税表</el-button>
        <el-button type="success" @click="HandleRoute('/host-application')">住家入驻表单</el-button>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        HandleRoute(name) {
            this.$router.push({ path: `${name}` })
        },
    },
}
</script>

<style lang="scss" scoped>
.flex-sb {
    width: 100%;
    margin: 40px auto;

    a {
        color: #fff;
    }
}
</style>